<template>
	<div>
		<f-default-header
			:title="$t('pages.settings.title')"
			:subtitle="$t('pages.settings.subtitle.purchaseIntervals')" />

		<f-data-form />

		<f-actions-btn-holder>
			<span v-if="!flags.creating">
				<v-btn elevation="0" large @click="remove">
					<span class="warning--text">remover</span>
				</v-btn>
				&nbsp;
			</span>
			<v-btn color="secondary" elevation="0" large @click="back">
				{{ $t("labels.btnBack") }}
			</v-btn>
			&nbsp;
			<v-btn color="primary" elevation="0" large @click="save">
				{{ $t("labels.btnSave") }}
			</v-btn>
		</f-actions-btn-holder>
	</div>
</template>

<script>
import FDataForm from "../../../components/settings/demoIntervals/DataForm.vue";

export default {
	components: {
		FDataForm,
	},
	data: () => ({
		demoIntervalID: null,
		flags: {
			creating: false,
			isValidDataForm: false,
		},
	}),
	created() {
		this.flags.creating = this.$route.name == "settings-demo-intervals-create";
		if (!this.flags.creating) {
			this.demoIntervalID = this.$route.params.id;
			this.getData();
		}
	},
	methods: {
		async getData() {
			await this.$store.dispatch("demoIntervals/get", this.demoIntervalID);
		},
		async save() {
			if (this.flags.creating) {
				await this.$store.dispatch("demoIntervals/create");
				this.$store.commit("snackbar/show", {
					content: "Intervalo criado com sucesso!",
					type: "success",
				});
			} else {
				await this.$store.dispatch("demoIntervals/edit");
				this.$store.commit("snackbar/show", {
					content: "Intervalo editado com sucesso!",
					type: "success",
				});
			}
			this.back();
		},
		async remove() {
			var response = await this.$store.dispatch("dialog/show", {
				title: "Remover",
				content: "Você deseja remover esse intervalo?",
			});
			if (response) {
				await this.$store.dispatch("demoIntervals/remove");
				this.back();
			}
		},
		back() {
			this.$router.push({
				name: "settings-demo-intervals-list",
			});
		},
	},
};
</script>
