export default {
	data: () => ({
		date: {
			begin: '',
			end: ''
		},
		modal: {
			begin: false,
			end: false
		},
		flags: {
			isLoaded: false
		}
	}),
	computed: {
		demoInterval() { return this.$store.state.demoIntervals.view },
		loading() { return !this.$store.state.system.loading.done },
		formatedBegin(){
			return this.dateFormater( this.date.begin )
		},
		formatedEnd(){
			return this.dateFormater( this.date.end )
		},
	},
	watch: {
		demoInterval(value){
			if( !this.flags.isLoaded ){
				this.date.begin = value.begin.slice(0,10)
				this.date.end = value.end.slice(0,10)
			}
		},
		formatedBegin(_) {
			this.demoInterval.begin = this.date.begin
		},
		formatedEnd(_) {
			this.demoInterval.end = this.date.end
		}
	},
	methods: {
		dateFormater(date) {
			if( date ){
				const [year, month, day] = date.split('-')
				return `${day}/${month}/${year}`
			}

			return ''
		}
	}
}